import logo from "../images/ascendant-logo.svg";

const Header = (props) => {
    let logoContainerStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 6,
    };

    let descriptionStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    };

    let linksStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        gap: 6,
    };

    return (
        <div className="header">
            <div style={logoContainerStyle}>
                <div>
                    <img
                        src={logo}
                        width="48"
                        height="48"
                        alt="Ascendant logo"
                    />
                </div>
                <div style={descriptionStyle}>
                    <header className="appTitle">Ascendant</header>
                </div>
            </div>
            <div style={linksStyle} className="links">
                <a href="https://discord.gg/uZtBF8x9Aj">Discord</a> |{" "}
                <a href="https://twitter.com/mesh"> Twitter</a>
            </div>
        </div>
    );
};

export default Header;
