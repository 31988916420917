import Hero from "./Hero";
import React from "react";

import activitiesImg from "../images/screenshots/activities.png";
import gameChartImg from "../images/screenshots/game-chart.png";
import activityImg from "../images/screenshots/activity.png";
import chartImg from "../images/screenshots/chart.png";
import heatmapImg from "../images/screenshots/heatmap.png";
import mapImg from "../images/screenshots/maps.png";
import fireteamImg from "../images/screenshots/fireteam.png";
import sessionImg from "../images/screenshots/session-comp.png";
import activityPlayerImg from "../images/screenshots/activity-player.png";
import trialsDashboardImg from "../images/screenshots/dashboard-trials.png";

const HeroContainer = (props) => {
    let rootStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 30,
    };

    return (
        <div style={rootStyle}>
            <Hero
                image={activitiesImg}
                description="View individual activity summaries across all activities."
            />

            <Hero
                image={chartImg}
                description="Visualize individual game performance over time"
            />

            <Hero
                image={activityImg}
                description="Detailed per-team and per-player performance breakdown for individual activities"
            />

            <Hero
                image={trialsDashboardImg}
                description="Detailed information on current trials card, including odds of going flawless."
            />

            <Hero
                image={sessionImg}
                description="Track stats and Glory gain / loss over gameplay sessions"
            />

            <Hero
                image={heatmapImg}
                description="Heatmap of gameplay intensity"
            />

            <Hero
                image={mapImg}
                description="View per map stats and performance"
            />

            <Hero
                image={gameChartImg}
                description="Game activity including mercy rates, completion rates, and joining late"
            />

            <Hero
                image={fireteamImg}
                description="Track fireteam member performances as they play with you"
            />

            <Hero
                image={activityPlayerImg}
                description="Detailed player performance for each activity"
            />
        </div>
    );
};

export default HeroContainer;
