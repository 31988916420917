import Feature from "./Feature";

const Features = (props) => {
    let rootStyle = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "30px",
    };

    let data = [
        "Syncs all PVP activity data for player",
        "Slice data by time period and game mode",
        "Export to CSV and JSON",
        "Integrate with ChatGPT data analysis via CSV export",
        "Fix missing and corrupt data from API",
        "Add support for modes not supported by API, such as Checkmate, Iron Banner Tribute and competitive sub-modes",
    ];

    let stats = [
        "Match stats, win rates and status, current and all time streaks",
        "Detailed break down of in game stats across games",
        "Kills breakdown by weapon and ability types",
        "Detailed weapon usage and performance",
        "Overview of matchmaking balance and teammate and opponents performance",
        "Weapon meta for your specific matches",
        "Breakdown of top medals",
    ];

    let general = [
        "Display current activity and map as you are loading into match",
        "Detailed experience data, including latest gains / losses",
        "Chance of going flawless based on current card on historic win rate",
        "Trials card status and progress",
        "Session mode to track stats during specific game play sessions",
        "Track overall Glory gained / losses during competitive session",
        "Track your fireteam members performance as they play with you",
        "Latest news and alerts from Bungie",
        "Quickly link out to player stats to popular Destiny 2 community sites (right click on names)",
    ];

    let charts = [
        "Visualize game play performance and results over time",
        "View changes in game status and match results over time",
        "Key meta shifts marked on charts so you can see impact on performance",
        "View per map information and stats",
        "Heatmap of playtime intensity over time",
    ];

    return (
        <div style={rootStyle} className="features">
            <Feature features={stats} title="Stats" />
            <Feature features={general} title="General" />
            <Feature features={charts} title="Charts" />
            <Feature features={data} title="Data" />
        </div>
    );
};

export default Features;
