import React, { useState, useEffect } from "react";

const Hero = (props) => {
    let image = props.image;
    let description = props.description;
    let width = props.width ? props.width : "30%";

    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    let rootStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: width,
        gap: 6,
    };

    let modalRootStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    let modalContentStyle = {
        maxWidth: "80%",
    };

    // Handler for the Escape key
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setModalOpen(false);
        }
    };

    // Attach and detach the keydown event listener based on the modal's state
    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isModalOpen]); // Dependency array ensures this runs only when isModalOpen changes

    return (
        <>
            <div style={rootStyle} onClick={toggleModal} className="hover">
                <img
                    src={image}
                    width="100%"
                    alt="Dashboard Screenshot"
                    className="imageDropShadow"
                />

                <div>{description}</div>
            </div>

            {isModalOpen && (
                <div
                    style={modalRootStyle}
                    onClick={toggleModal}
                    className="hover"
                >
                    <div style={modalContentStyle}>
                        <img
                            src={image}
                            width="100%"
                            alt="Dashboard Screenshot"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Hero;
