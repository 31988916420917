import dashboardImg from "../images/screenshots/dashboard.png";
import React from "react";

import Hero from "./Hero";
import FAQ from "./FAQ";
import HeroContainer from "./HeroContainer";
import Header from "./Header";
import Features from "./Features";
import { release } from "../core/config";

const App = (props) => {
    let detailsStyle = {
        display: "flex",
        flexDirection: "column",
        gap: 50,
    };

    let heroHighlightStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    };

    return (
        <div className="app">
            <Header />

            <div style={detailsStyle}>
                <div className="headline">
                    Destiny 2 PVP Dashboard for MacOS
                </div>
                <div style={heroHighlightStyle}>
                    <Hero
                        image={dashboardImg}
                        description="Comprehensive breakdown of stats, meta and matchmaking based on time period (from - to) and mode."
                        width="50%"
                    />
                </div>
                <Features />
                <div>
                    <a className="headline" href={release.path} alt="download">
                        {release.version} ({release.date})
                    </a>
                    <br />
                    MacOS Sonoma 14.0 Required
                </div>
            </div>

            <div className="contentContainer">
                <HeroContainer />
                <FAQ />
                <div>&nbsp;</div>
            </div>
        </div>
    );
};

export default App;
