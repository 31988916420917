const Feature = (props) => {
    let rootStyle = {
        width: "300px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
    };

    let features = props.features;
    let title = props.title;

    return (
        <div style={rootStyle}>
            <div className="featureTitle underline">{title}</div>
            <ul>
                {features.map((feature) => (
                    <li>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

export default Feature;
