import Markdown from "react-markdown";
import { release } from "../core/config";

const FAQ = (props) => {
    let questions = [
        {
            question: "What is Ascendant?",
            answer: "Ascendant is a MacOS application for tracking and analyzing Destiny 2 PVP data.",
        },

        {
            question: "What are the system requirements?",
            answer: "MacOS running Sonoma (14)",
        },

        {
            question: "How do I get it?",
            answer: `You can download the current release from [here](${release.path})`,
        },

        {
            question: "Where can I find release notes?",
            answer: "You can find release notes [here](/releases.txt)",
        },

        {
            question: "How does it work?",
            answer: "The application works by syncing all of your Destiny 2 PVP match data into a Sqlite3 database. It then gives you views of that data based on the start / end moments that you choose along with game mode.",
        },

        {
            question: "Are there any known issues?",
            answer: `There should not be anything major. A couple of things to keep an eye on:

* Initial data sync can take a long time
* May hit occasional crashes (please report on [Discord](https://discord.gg/uZtBF8x9Aj))
* When loading large data sets, there may be a slight delay
            `,
        },

        {
            question: "What additional features are you considering?",
            answer: `I'm open to any ideas, suggestions but on the top of my list:

* Better activity detail data and design
* Search mode for advanced search and filters
            `,
        },

        {
            question:
                "Where can I report issues, make feature requests or just chat?",
            answer: "You can report any issues, share feature requests or just chat on the app's [Discord server](https://discord.gg/uZtBF8x9Aj)",
        },

        {
            question: "Why do I get a security warning when I launch it?",
            answer: "This is due to the way Apple treats apps that are not installed from the app store. You can find information on how to give permissions [here](https://support.apple.com/guide/mac-help/open-a-mac-app-from-an-unidentified-developer-mh40616/mac)",
        },

        {
            question: "Do you plan to release on any other platforms?",
            answer: "I am looking at adding support for Apple iPad and potentially Apple TV and Apple Watch.",
        },
        {
            question: "Why does the initial data sync take so long?",
            answer: `The first time data is synced, the application has to load all of the player's data.    
    
Depending on the number of matches played, this can take a while (as a general rule, about 1 minute for every 1,000 matches played).    

In addition to loading match data, the application also attempts to fix data from the API which is missing or broken (which requires a lot of additional calls the the Bungie API servers). In general, once the data has been synced the first time, then subsequent syncs should be quick.`,
        },

        {
            question: "What is the Session moment?",
            answer: `The session moment sets the moment / time period from the time you select it. This is useful if you want to track stats during a specific gameplay session. Just choose _Session_ as the from moment, and _Now_ as to the to moment when you begin playing.

When using _Session_ moment and in _Competitive_ Mode, then total Glory gained / lost during session will be displayed.`,
        },

        {
            question: "What is the 'chance' stat in the Trials Card View?",
            answer: "This displays the estimated odds of going flawless based on your current card, card status and win rate. It uses a negative binomial distribution approach with fixed odds based on win rate from the past month, assuming each match is an independent event. It is meant to give a general idea of how likely you are to go flawless based on current card state and win rate, but is not meant to be a precise prediction.",
        },

        {
            question: "What does the Weapon Meta data represent?",
            answer: "Weapon Meta shows weapon usage by all players in your lobby who are not in your fireteam.",
        },

        {
            question: "What does the Team Balance data represent?",
            answer: "Team balance shows performance of all players in your lobbies, excluding you.",
        },

        {
            question: "What is Low Energy Mode?",
            answer: "Low Energy Mode (File - Low Energy Mode) turns off some of the animations, and increases the amount of time between data updates. The app will automatically go into Low Energy Mode when the app is in the background or minimized.",
        },

        {
            question:
                "Is it possible to use the data from the app with ChatGPT?",
            answer: "Yes! You can export the current view by selecting File - Export, with options for JSON and CSV. This will export a summary of match data, which you can then use to upload to ChatGPT and ask it to provide analysis, insights and commentary on it.",
        },

        {
            question: "Are you looking for contributors?",
            answer: "Yes! I am particularly looking for any help to improve the overall design and UX / UI of the app and website. If you are interested, send a message on the [Discord](https://discord.gg/uZtBF8x9Aj)",
        },

        {
            question: "Has anyone else contributed?",
            answer: "Yes! [Kushagra Agarwal](https://twitter.com/kushsolitary) helped work through some particularly nasty SwiftUI issues.",
        },

        {
            question: "Who built the app?",
            answer: `The app is developed as a hobby by [Mike Chambers](https://www.mikechambers.com)
            
Mike has developed a number of other projects including:
* **[Redrix.io](https://redrix.io)** : Destiny 2 mobile pvp app
* **[dcli](https://github.com/mikechambers/dcli)** open source collection of utilities and apps that provide a command line interface (CLI) for viewing player stats and data from Destiny 2
* **[DARCI](https://github.com/mikechambers/darci)**  a self hosted web platform for aggregating, viewing and analyzing Destiny 2 PVP stats.
* **[TwitchLive for Firefox](https://addons.mozilla.org/en-US/firefox/addon/twitch-live-for-firefox/)** browser extension for Firefox that makes it easy to check which of your favorite Twitch.tv channels are currently live
* **[TwitchLive for Chrome](https://chromewebstore.google.com/detail/twitch-live/iiljidcefnbhbpamageahhblhbbhhopm)** browser extension for Google Chrome that makes it easy to check which of your favorite Twitch.tv channels are currently live
            `,
        },

        {
            question: "Is there a Patreon to support the project?",
            answer: "No, I am doing this for fun. But if you would like to show your appreciation, you can check out my [Amazon Wish List](https://www.amazon.com/hz/wishlist/ls/2ISHCVI9YBCJU?ref_=wl_share)",
        },
    ];

    let rootStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "50%",
        gap: 20,
        minWidth: 360,
    };

    let answerStyle = {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    };

    return (
        <div style={rootStyle}>
            {questions.map((q) => (
                <div>
                    <div className="faqQuestion">{q.question}</div>
                    <div className="faqAnswer" style={answerStyle}>
                        <Markdown>{q.answer}</Markdown>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
